import "./index.css";
import "./app.css";

import ai_creating_img from "./assets/ai_creating.png";

const App = () => {
  return (
    <div className="app">
      <div className="2xl:container 2xl:mx-auto mt-4 md:px-8">
        {/* header*/}
        <header className="flex z-10 color_primary sticky top-0 px-6 py-4 lg:grid grid-cols-3 rounded-lg">
          <div className="flex gap-2 items-center">
            <svg
              className="w-[30px] h-[20px] lg:w-[60px] lg:h-[40px]"
             
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 129.2899932861328 100.02000427246094"
            >
              <path
                d="M28.72 100L59.6 0H30.88L16 48.13v.11L0 100z"
                fill="#00c2f5"
              ></path>
              <path
                fill="#333"
                d="M68.22 0L37.34 100h28.72L96.94 0H68.22zM87.53 57.6l7.71 25v.12l5.35 17.3h28.7l-27.4-88.9z"
              ></path>
            </svg>
            <span className="text-xl lg:text-2xl font-extrabold">CREATE AI</span>
          </div>

          <nav className="hidden xl:flex justify-center items-center gap-4">
            <span>О нас</span>
            <span>Услуги</span>
            <span>Как мы работаем?</span>
            <span>Обсудить проект</span>
          </nav>
          <div className="hidden xl:flex justify-end items-center gap-4">
            <span>WA</span>
            <span>Tg</span>
            <span>Ph</span>
            <span>@</span>
            <span>Тендерный отдел</span>
          </div>
        </header>

        <main>
          {/* hero */}
          <section className="flex flex-col content-center h-[80vh] bg-white dark:bg-gray-900">
            <div className="h-full mx-auto flex justify-center items-center max-w-screen-xl px-4 pt-4 lg:gap-8 xl:gap-0 lg:pt-8">
              <div className="relative">
                <h1 className="text-4xl text-center font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-white">
                  ВНЕДРИМ
                  <br />
                  ИСКУСТВЕННЫЙ
                  <br />
                  ИНТЕЛЛЕКТ <br />В ВАШ БИЗНЕС
                </h1>
                <span className="absolute color_primary rounded-full px-2 py-1 top-[-30%] left-[-5%] lg:top-[-20%] lg:left-[-10%]">
                  Победа над конкурентами
                </span>
                <span className="absolute color_primary rounded-full px-2 py-1 top-[-75%] right-[-5%] lg:top-[-15%] lg:right-[-10%]">
                  Снижение издержек
                </span>
                <span className="absolute color_primary rounded-full px-2 py-1 right-[-7%] bottom-[-30%] lg:right-[-20%] lg:bottom-[-15%]">
                  Автоматизация бизнес-процессов
                </span>

                <span className="absolute color_primary rounded-full px-2 py-1 left-[-4%] bottom-[-60%] lg:left-[-15%] lg:bottom-[-25%]">
                  Решение для селлеров WB OZ YM SM
                </span>
                <span className="absolute color_primary rounded-full px-2 py-1 bottom-[-95%] right-[0] lg:bottom-[-40%] lg:right-[0]">
                  Увеличение производительности
                </span>
              </div>

              {/*
            <div className="mt-[64px] lg:mt-0 sm:w-full lg:w-[30%] ">
              <form action="#" className="space-y-8">
                <div>
                  <label
                    for="name"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Имя
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                    placeholder="Иван"
                    required
                  />
                </div>
                <div>
                  <label
                    for="phone"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Телефон
                  </label>
                  <input
                    type="phone"
                    id="phone"
                    className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                    placeholder="+7 999 999 99 99"
                    required
                  />
                </div>
                <div className="sm:col-span-2">
                  <label
                    for="message"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                  >
                    Краткое описание задачи
                  </label>
                  <textarea
                    id="message"
                    rows="2"
                    className="resize-none block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Leave a comment..."
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
                >
                  Связаться со мной
                </button>
              </form>
            </div>*/}
            </div>
          </section>

          {/* reasons */}
          <section className="bg-white dark:bg-gray-900">
            <div className="flex flex-col justify-center gap-8 items-center py-8 px-4 lg:py-16 lg:px-6">
              <div className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                  ЗАЧЕМ ВАМ ВНЕДРЯТЬ ИСКУСТВЕННЫЙ ИНТЕЛЛЕКТ В БИЗНЕС?
                </h2>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 lg:grid-rows-3 gap-4">
                <div className="p-4 border-solid border-4 border-black card rounded-lg">
                  AI автоматизирует рутинные задачи, что значительно повышает
                  эффективность работы и увеличивает производительность бизнеса
                </div>
                <div className="p-4 border-solid border-4 border-black card rounded-lg">
                  AI выявляет скрытые закономерности и тренды, что приводит к
                  улучшению стратегий, оптимизации процессов, повышению
                  конкурентоспособности.
                </div>
                <div className="sm:col-span-1 lg:col-span-1 p-4 border-solid border-4 border-black card rounded-lg">
                  AI улучшает удовлетворенность клиентов и укрепляет их
                  лояльность к бренду за счёт создания для них
                  персонализированного опыта на основании их предпочтений и
                  поведения.
                </div>
                <div className="color_primary p-4 border-solid border-4 border-black card rounded-lg">
                  AI помогает снизить операционные затраты, автоматизируя
                  процессы и улучшая эффективность
                </div>

                <div className="p-4 border-solid border-4 border-black card rounded-lg">
                  AI анализирует данные и прогнозирует спрос, что позволяет
                  определить оптимальные цены для максимизации прибыли.
                </div>
                <div className="p-4 border-solid border-4 border-black card rounded-lg">
                  AI помогает бизнесу обнаружить новые возможности и тренды на
                  рынке, которые могут привести к созданию новых продуктов или
                  услуг.
                </div>
                <div className="color_primary p-4 border-solid border-4 border-black card rounded-lg">
                  AI предоставляет быстрые и точные ответы на вопросы клиентов,
                  а также предлагает рекомендации и персонализированные
                  предложения, что улучшает обслуживание клиентов.
                </div>

                <div className="color_primary col-span-1 lg:col-span-2 p-4 border-solid border-4 border-black  card rounded-lg">
                  AI помогает селлерам на маркетплейсах автоматизировать систему
                  отчетности, собирать данные дашбордов всех магазинов в одном
                  месте, прогнозировать закупку товаров.
                </div>
              </div>
              <button
                type="submit"
                className="btn_primary w-full max-w-[260px] inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
              >
                Оставить заявку
              </button>
            </div>
          </section>

          {/* what we do */}
          <section className="bg-white dark:bg-gray-900">
            <div className="flex flex-col justify-center gap-8 items-center py-8 px-4 lg:py-16 lg:px-6">
              <div className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                  ЧТО МЫ УМЕЕМ?
                </h2>
              </div>
              <div className="w-full lg:px-[64px] flex flex-col lg:flex-row justify-center gap-8 lg:gap-16">
                <div className="flex flex-col justify-between items-stretch gap-4 lg:gap-0">
                  <div className="p-4 border-solid border-4 border-black card rounded-lg">
                    <h3 className="text-2xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                      Продуктовая разработка
                    </h3>
                    <div>
                      Разработка мобильного приложения или веб-сервиса с
                      искусственным интеллектом
                    </div>
                  </div>
                  <div className="p-4 border-solid border-4 border-black card rounded-lg">
                    <h3 className="text-2xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                      Разработка полного цикла
                    </h3>
                    <div>
                      Разработка искусственного интеллекта: сбор датасета,
                      обучение, внедрение
                    </div>
                  </div>
                  <div className="p-4 border-solid border-4 border-black card rounded-lg">
                    <h3 className="text-2xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                      Разработка с быстрым запуском
                    </h3>
                    <div>Создание MVP продукта с искусственным интеллектом</div>
                  </div>
                </div>
                <div className="color_primary h-full p-4 sm:w-full lg:w-[30%] border-solid border-4 border-black card rounded-lg">
                  <form action="#" className="space-y-4">
                    <div>
                      <label
                        for="name"
                        className="block mb-2 text-sm font-medium text-white"
                      >
                        Имя
                      </label>
                      <input
                        type="text"
                        id="name"
                        className="input_primary block p-2.5 w-full text-sm text-gray-900 rounded-lg shadow-sm border focus:ring-primary-500 focus:border-primary-500"
                        placeholder="Иван"
                        required
                      />
                    </div>
                    <div>
                      <label
                        for="phone"
                        className="block mb-2 text-sm font-medium text-white"
                      >
                        Телефон
                      </label>
                      <input
                        type="phone"
                        id="phone"
                        className="input_primary block p-2.5 w-full text-sm text-gray-900 rounded-lg shadow-sm border focus:ring-primary-500 focus:border-primary-500"
                        placeholder="+7 999 999 99 99"
                        required
                      />
                    </div>
                    <div className="sm:col-span-2">
                      <label
                        for="message"
                        className="block mb-2 text-sm font-medium text-white"
                      >
                        Краткое описание задачи
                      </label>
                      <textarea
                        id="message"
                        rows="2"
                        className="input_primary resize-none block p-2.5 w-full text-sm text-gray-900 rounded-lg shadow-sm border focus:ring-primary-500 focus:border-primary-500"
                        placeholder="Leave a comment..."
                      />
                    </div>
                    <button
                      type="submit"
                      className="btn_white rounded-lg w-full inline-flex items-center justify-center px-5 py-3 text-base font-medium text-cent"
                    >
                      Свяжитесь со мной
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </section>

          {/* how we work */}
          <section className="bg-white dark:bg-gray-900">
            <div className="flex flex-col justify-center gap-8 items-center py-8 px-4 lg:py-16 lg:px-6">
              <div className="lg:pl-12 self-start font-light text-gray-500 sm:text-lg dark:text-gray-400">
                <h2 className="text-left mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                  КАК МЫ РАБОТАЕМ?
                </h2>
              </div>

              <div className="color_primary w-full flex justify-stretch lg:justify-stretch items-center gap-12 p-6 lg:p-12 rounded-[1.5rem]">
                <div
                  style={{
                    backgroundImage: `url(${ai_creating_img})`,
                    backgroundSize: "cover",
                  }}
                  className="hidden lg:block flex-1 self-stretch rounded-lg"
                ></div>

                <div className="flex flex-col w-full lg:w-auto lg:justify-start items-center self-stretch gap-2">
                  <div className="color_white flex-1 flex self-stretch items-center gap-[12px] p-4 card rounded-lg">
                    <div className="text-3xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                      1
                    </div>
                    <div>Анализ поставленной задачи</div>
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3"
                    />
                  </svg>

                  <div className="color_white flex-1 flex self-stretch items-center gap-[12px] p-4 border-solid border-4 border-black card rounded-lg">
                    <div className="text-3xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                      2
                    </div>
                    <div>Согласование плана работ</div>
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3"
                    />
                  </svg>

                  <div className="color_white flex-1 flex self-stretch items-center gap-[12px] p-4 border-solid border-4 border-black card rounded-lg">
                    <div className="text-3xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                      3
                    </div>
                    <div>
                      Разработка и внедрение искусственного интеллекта
                      <br />
                      под Ваши бизнес-задачи
                    </div>
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3"
                    />
                  </svg>

                  <div className="color_white flex-1 flex self-stretch items-center gap-[12px] p-4 border-solid border-4 border-black card rounded-lg">
                    <div className="text-3xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                      4
                    </div>
                    <div>Тестирование и оптимизация созданного решения</div>
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3"
                    />
                  </svg>

                  <div className="color_white flex-1 flex self-stretch items-center gap-[12px] p-4 border-solid border-4 border-black card rounded-lg">
                    <div className="text-3xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                      5
                    </div>
                    <div>Поддержка и сопровождение</div>
                  </div>
                  <button
                    type="submit"
                    className="btn_white rounded-lg w-full inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center"
                  >
                    Свяжитесь со мной
                  </button>
                </div>
              </div>
            </div>
          </section>

          {/* sticky block */}
          <section className="bg_imaged rounded-[1.5rem] { bg-white dark:bg-gray-900 mt-12">
            <div className="py-8 px-4 lg:py-16 lg:px-6 card">
              <div className="block h-[100vh]  ">
                <div className="sticky text-center m-0 top-[40%]">
                  <h3 className="mb-8 text-4xl tracking-tight font-extrabold  text-white">
                    ИСКУССТВЕННЫЙ ИНТЕЛЛЕКТ ПОБЕДИТ ВАШИХ КОНКУРЕНТОВ
                  </h3>
                  <button className="rounded-lg btn_white w-full max-w-[260px] inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center">
                    ВНЕДРИТЬ ИИ
                  </button>
                </div>
              </div>
              <div className="flex justify-center items-center mt-8"></div>
            </div>
          </section>

          {/* form */}
          <section className="bg-white dark:bg-gray-900 mt-12">
            <div className="flex flex-col justify-center gap-8 items-center p-8 lg:p-12 color_primary rounded-[1.5rem]">
              <div className="w-full font-light text-gray-500 sm:text-lg dark:text-gray-400">
                <h2 className=" text-left text-3xl lg:text-4xl tracking-tight font-extrabold text-white">
                  Готовы <br className="lg:hidden" />
                  обсудить проект?
                </h2>
              </div>
              <div className="w-full flex justify-stretch lg:justify-stretch items-center gap-8 ">
                <div className="w-full h-full p-4 sm:w-full lg:w-[30%] color_white card rounded-lg">
                  <form action="#" className="space-y-4">
                    <div>
                      <label
                        for="name3"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Имя
                      </label>
                      <input
                        type="text"
                        id="name3"
                        className="input_primary block p-2.5 w-full text-sm text-gray-900 rounded-lg shadow-sm border focus:ring-primary-500 focus:border-primary-500"
                        placeholder="Иван"
                        required
                      />
                    </div>
                    <div>
                      <label
                        for="phone3"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Телефон
                      </label>
                      <input
                        type="phone"
                        id="phone3"
                        className="input_primary block p-2.5 w-full text-sm text-gray-900 rounded-lg shadow-sm border focus:ring-primary-500 focus:border-primary-500"
                        placeholder="+7 999 999 99 99"
                        required
                      />
                    </div>
                    <div className="sm:col-span-2">
                      <label
                        for="message3"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                      >
                        Краткое описание задачи
                      </label>
                      <textarea
                        id="message3"
                        rows="2"
                        className="input_primary resize-none block p-2.5 w-full text-sm text-gray-900 rounded-lg shadow-sm border focus:ring-primary-500 focus:border-primary-500"
                        placeholder="Leave a comment..."
                      />
                    </div>
                    <button
                      type="submit"
                      className="btn_primary rounded-lg w-full inline-flex items-center justify-center px-5 py-3 text-base font-medium text-cent"
                    >
                      Обсудить проект
                    </button>
                  </form>
                </div>
                <div
                  style={{
                    backgroundImage: `url(${ai_creating_img})`,
                    backgroundSize: "cover",
                  }}
                  className="max-h-[372px] hidden lg:block flex-1 self-stretch rounded-lg"
                ></div>
              </div>
            </div>
          </section>
        </main>

        {/* footer */}
        <footer className="flex flex-col lg:flex-row gap-8 lg:gap-0 justify-around mt-32 p-12">
          <div className="flex flex-col gap-12">
            <div className="flex flex-col gap-4">
              <span className="flex gap-2 items-center">
                <svg
                  style={{ width: "60px", height: "40px" }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 129.2899932861328 100.02000427246094"
                >
                  <path
                    d="M28.72 100L59.6 0H30.88L16 48.13v.11L0 100z"
                    fill="#00c2f5"
                  ></path>
                  <path
                    fill="#333"
                    d="M68.22 0L37.34 100h28.72L96.94 0H68.22zM87.53 57.6l7.71 25v.12l5.35 17.3h28.7l-27.4-88.9z"
                  ></path>
                </svg>
                <span className="text-2xl font-extrabold">CREATE AI</span>
              </span>
              <div className="flex flex-col gap-2 font-medium">
                <span>ОГРНИП 322619600111350</span>
                <span>ИНН 616812838921</span>
              </div>
            </div>
            <div>ДАННЫЙ САЙТ СОЗДАН НЕЙРОСЕТЬЮ</div>
          </div>
          <div className="flex flex-col justify-between">
            <div className="flex flex-col">
              <a href="#about">О нас</a>
              <a href="#services">Услуги</a>
              <a href="#howwork">Как мы работаем?</a>
              <a href="#discuss">Обсудить проект</a>
            </div>
            <div>Политика конфиденциальности</div>
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex gap-4">
              <span>WA</span>
              <span>TG</span>
            </div>
            <div>+7 999 999 99 99</div>
            <div>createai@mail.ru</div>
            <button className="btn_primary rounded-lg w-full inline-flex items-center justify-center px-5 py-3 text-base font-medium text-cent">
              Тендерный отдел
            </button>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default App;
